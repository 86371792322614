.select2-container--default {

    .select2-selection--single {
        @extend .form-control;
        padding: 3px;

        .select2-selection__arrow {
            top: 3px;
        }
    }

    .select2-selection--multiple {
        @extend .form-control;
        padding: 0 5px;
        height: auto;
        min-height: 34px;

        .select2-selection__rendered {
            padding-left: 0;

            .select2-selection__choice {
                margin-top: 5px;
                border-radius: 0;
                background: $color-bmw-blue;
                border-color: darken($color-bmw-blue, 15%);
                font-weight: bold;
                color: white;

                .select2-selection__choice__remove {
                    color: white;
                    font-size: 1em;
                    margin-right: 4px;

                    &:hover {
                        color: darken($color-bmw-blue, 25%);;
                    }
                }
            }

            .select2-search__field {
                margin-top: 0;
            }
        }
    }
}