.table-responsive {
}

.skin-bmw {
    .table-buttons {
        > a {
            padding: 2px;
            text-align: center;

            @media (min-width: $screen-sm-min) {
                display: block;
                float: left;
                width: percentage(1*0.5);
            }
        }
    }

    .table-striped {

    }

    .dataTables_paginate {
        .pagination {
            .paginate_button {
                &.disabled {
                    a {
                        background: $color-bmw-gray-light;
                    }
                }

                &.active {
                    color: $color-bmw-light;
                    font-weight: bold;

                    a {
                        background: $color-bmw-blue;
                        font-weight: bold;
                    }
                }

                &:focus {
                    outline: none;
                }

                a {
                    border-radius: 0;
                    font-weight: 300;
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}