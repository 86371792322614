// Fonts
//@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
$fontPath: '../../fonts' !default;
@import "~bmw_marketing_2019_boilerplate/scss/bmw_boilerplate";


// Variables
//@import "variables";
// Bootstrap
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
//Inspina Theme
@import "admin/inspinia";
// VENDORS
//@import "~datatables.net-bs/css/dataTables.bootstrap.css";
@import './../vendors/dataTables/css/dataTables.bootstrap.min.css';
@import "~select2/src/scss/core";
@import "./../vendors/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~toastr/toastr";
//@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.css";
//@import "~jquery-sortable/source/css/application.css.sass";
//overwrite
@import "admin/overwrite/toastr";
@import "admin/base/globals";
@import "admin/base/colors";
@import "admin/base/fonts";
@import "admin/modules/select2";
@import "admin/modules/form";
@import "admin/modules/modal";
@import "admin/modules/slick";
@import "admin/modules/btn";
@import "admin/modules/alerts";
@import "admin/modules/grid";
@import "admin/modules/notifications";
@import "admin/modules/sortable";
@import "admin/modules/box";
//LAYOUTS
@import "admin/layouts/sortable";
@import "admin/layouts/external";
@import "admin/layouts/tables";
@import "admin/layouts/sidebar";
@import "admin/layouts/skin-bmw";
@import "admin/layouts/flex";