/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.7.1
 *
*/
// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
// Variables, Mixins
@import "resources/assets/vendors/inspinia-2.7.1/scss/variables";

$navy: #75C35E; // Primary color
$dark-gray: #C2C2C2; // Default color
$blue: #1c69d4; // Success color
$lazur: #28AEE3; // Info color
$yellow: #ECAF56; // Warrning color
$red: #CC3D41; // Danger color
@import "resources/assets/vendors/inspinia-2.7.1/scss/mixins";

// INSPINIA Theme Elements
@import "resources/assets/vendors/inspinia-2.7.1/scss/typography";
@import "resources/assets/vendors/inspinia-2.7.1/scss/navigation";
@import "resources/assets/vendors/inspinia-2.7.1/scss/top_navigation";
@import "resources/assets/vendors/inspinia-2.7.1/scss/buttons";
@import "resources/assets/vendors/inspinia-2.7.1/scss/badges_labels";
@import "resources/assets/vendors/inspinia-2.7.1/scss/elements";
@import "resources/assets/vendors/inspinia-2.7.1/scss/sidebar";
@import "resources/assets/vendors/inspinia-2.7.1/scss/base";
@import "resources/assets/vendors/inspinia-2.7.1/scss/pages";
@import "resources/assets/vendors/inspinia-2.7.1/scss/chat";
@import "resources/assets/vendors/inspinia-2.7.1/scss/metismenu";
@import "resources/assets/vendors/inspinia-2.7.1/scss/spinners";

// Landing page styles
@import "resources/assets/vendors/inspinia-2.7.1/scss/landing";

// RTL Support
@import "resources/assets/vendors/inspinia-2.7.1/scss/rtl";

// For demo only - config box style
@import "resources/assets/vendors/inspinia-2.7.1/scss/theme-config";

// INSPINIA Skins
@import "resources/assets/vendors/inspinia-2.7.1/scss/skins";
@import "resources/assets/vendors/inspinia-2.7.1/scss/md-skin";

// Media query style
@import "resources/assets/vendors/inspinia-2.7.1/scss/media";

// Custom style
// Your custom style to override base style
@import "resources/assets/vendors/inspinia-2.7.1/scss/custom";

// Clear layout on print mode
@media print {
    nav.navbar-static-side {
        display: none;
    }
    body {
        overflow: visible !important;
    }

    #page-wrapper {
        margin: 0;
    }
}

