.slick-list {
    .slick-slide {
        outline: none;
    }
    .slick-active {
        outline: none;

        .ibox-content {
            outline: none;
        }
    }
}