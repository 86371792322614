
.field-checkbox__input {
    position: relative;
    float: left;

    label {
        padding: 0 20px 0 30px;
        line-height: 24px;
        white-space: nowrap;
    }

    input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;

        &:not(:checked), &:checked {
            position: absolute;
            left: -9999px;

            + label {
                cursor: pointer;
                margin-bottom: 12px;

                &:before, &:after {
                    content: ' ';
                    position: absolute;
                    background: transparent;
                }

                &:before {
                    top: 0;
                    left: 0;
                    width: 24px;
                    height: 24px;
                    border: 1px solid $blue;
                    background: $color-bmw-light;
                }

                &:after {
                    top: 5px;
                    left: 5px;
                    width: 14px;
                    height: 14px;
                    background: $blue;

                    opacity: 0;
                }
            }
        }

        &:checked {
            + label {
                &:after {
                    opacity: 1;
                }
            }
        }

    }
}

.field-radio__input {
    position: relative;
    float: left;

    label {
        padding: 0 20px 0 30px;
        line-height: 24px;
        white-space: nowrap;
    }

    input[type="radio"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;

        &:not(:checked), &:checked {
            position: absolute;
            left: -9999px;

            + label {
                cursor: pointer;
                margin-bottom: 12px;

                &:before, &:after {
                    content: ' ';
                    position: absolute;
                    background: transparent;
                }

                &:before {
                    top: 0;
                    left: 0;
                    width: 24px;
                    height: 24px;
                    border: 1px solid $blue;
                    background: $color-bmw-light;
                    border-radius: 12px;
                }

                &:after {
                    top: 5px;
                    left: 5px;
                    width: 14px;
                    height: 14px;
                    background: $blue;
                    border-radius: 7px;

                    opacity: 0;
                }
            }
        }

        &:checked {
            + label {
                &:after {
                    opacity: 1;
                }
            }
        }

    }
}

.form-section {
    position: relative;
    &:after {
        position: absolute;
        content: ' ';
        bottom: -5px;
        width: 100%;
        border: 1px solid $skin-matt-color-greylight;
        left: 0;
    }
}

textarea {
    max-width: 100%;
}