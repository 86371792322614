.skin-bmw-external {

    hr {
        border-color: lighten(#262626, 60);
    }

    .footer-external {
        color: #262626;
        margin-bottom: 50px;
    }

    .middle-box {
        padding-top: 0;
    }

    .logo-name {
        font-size: 70px;
        line-height: 0.8;
        letter-spacing: -5px;
        padding: 15px;
        color: #262626;

        small {
            color: lighten(#262626, 30);
        }
    }

    .ibox-content {
        margin: 30px 0 0;
        //max-width: 300px;
    }
}

.form-reset {
    .control-label {
        text-align: left;
    }
}

.external-header {
    background: $color-bmw-light;

    .middle-box {
        padding: 10px 0;
        width: 100%;
        max-width: 800px;
    }
}

.external-footer {
    .middle-box {
        margin-top: 50px;
        padding: 10px 0;
        width: 100%;
        max-width: 800px;
    }
}