.sortable {
    list-style-type: none;
    padding: 10px 0;
    background-color: #FAFAFA;
    border: 1px solid #E3E3E3;
    max-height: 300px;
    min-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    margin: 0 0 20px 0;
    border-radius: 4px;
    li {
        display: block;
        margin: 5px;
        padding: 5px;
        border: 1px solid #E3E3E3;
        color: #0088CC;
        background: #FFFFFF;
        cursor: move;
        &.danger {
            background: $alert-danger-bg;
        }
        &.placeholder {
            position: relative;
            margin: 0;
            padding: 0;
            border: none;
            &:before {
                position: absolute;
                content: "";
                width: 100%;
                height: 0;
                margin-top: -3px;
                border-top: 1px solid #0088CC;
            }
        }
    }
}