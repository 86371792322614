.notifications {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .notification {
        flex: 0 1 auto;

        .small-box {
            height: 100%;
        }
    }
}
