
body {
    &.skin-bmw {
        .sidebar-collapse {
            .metismenu {
                padding-right: 0;

                .nav-header {
                    color: $color-bmw-light;
                    background: none;
                    padding: 15px 10px;
                }

                & > li > a {
                    color: #A7B1C2;
                    font-weight: 600;
                    padding: 14px 10px 14px 15px;
                }
                & > li.active > a {
                    color: #FFFFFF;
                }
                & .open > a,
                & .open > a:hover,
                & .open > a:focus {
                    background: #FFFFFF;
                }
                & > li > a i {
                    margin-right: 6px;
                }

                & > li > a:hover,
                & > li > a:focus {
                    background-color: #293846;
                    color: white;
                    transition: color 0.4s, background .4s, border .4s;
                }

                & > li.active {
                    //border-left: 4px solid $color-bmw-blue;
                    background: #293846;
                    transition: color 0.4s, background .4s, border .4s;
                }

                .nav-second-level li,
                .nav-third-level li {
                    border-bottom: none !important;
                }
                .nav-second-level li a {
                    padding: 7px 10px 7px 10px;
                    padding-left: 52px;
                }

                .nav > li > a:hover, .nav > li > a:focus {
                    background-color: #293846;
                    color: white;
                }

                .nav.nav-second-level > li.active {
                    border: none;
                }

                .nav > li.active {
                    background: #293846;
                }

                > li.nav-title {
                    > a {
                        background: #262626;
                        padding: 5px 10px;
                        //margin-top: 5px;
                        color: $color-bmw-light;
                        border-bottom: 2px solid $color-bmw-light;
                    }
                }

                > li.nav-separator {
                    > a {
                        text-align: center;
                        background: #3E495F;
                        padding: 5px 10px;
                    }
                }

            }

        }

        &.mini-navbar {
            .sidebar-collapse {
                .metismenu {

                    .nav-header {
                        padding: 0;
                    }

                    a {
                        text-align: center;

                        .fa {
                            font-size: 1.4em;
                        }
                    }
                }
            }

        }

    }
}

