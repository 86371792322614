.color-matt {
    @each $color in $skin-matt-colors {
        &-#{"" + nth($color, 1)} {
            color: nth($color, 2);
        }
    }
}

.alert-matt {
    @each $color in $skin-matt-colors {
        &-#{"" + nth($color, 1)} {
            background-color: lighten(nth($color, 2), 35);
            border-color: lighten(nth($color, 2), 10);
            color: darken(nth($color, 2), 20);
            //color: nth($color, 3);
        }
    }
}

.label-matt {
    @each $color in $skin-matt-colors {
        &-#{"" + nth($color, 1)} {
            background-color: nth($color, 2);
            //color: darken(nth($color, 2), 20);
            color: nth($color, 3);
        }
    }
}

.bg-matt {
    @each $color in $skin-matt-colors {
        &-#{"" + nth($color, 1)} {
            background-color: nth($color, 2);
            color: nth($color, 3);
        }
    }
}
