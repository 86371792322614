//Like BMW
//$color-bmw-light-gray: #cccccc;
//$color-bmw-gray: #4a4a49;
//$color-bmw-gray-mid: #626262;
$color-bmw-gray-light: #F2F2F2;
$color-bmw-red: #D20000;
//$color-bmw-dark: #000;
$color-bmw-light: #FFFFFF;
$color-bmw-blue: #1c69d4;
$color-bmw-light-blue: #1C69D4;
$color-bmw-blue-m: #1C69D4;

$color-bmw-error: $color-bmw-red;
$color-bmw-theme: $color-bmw-blue;

// Main colors
$skin-bmw-blue: $color-bmw-blue;
$skin-bmw-color: $skin-bmw-blue;
$skin-bmw-nav-bg: #F2F2F2;
$skin-bmw-a-color: #9EA6B9;
$skin-bmw-page-bg: $skin-bmw-nav-bg;

$skin-bmw-sent: #B4E1AB;
$skin-bmw-deleted: #FFC1BE;
$skin-bmw-completed: #E4F5FF;

//Colori matt
$white: #FFFFFF;
$skin-matt-color-bluelight: #28AEE3;
$skin-matt-color-blue: #428BCA;
$skin-matt-color-bluedark: #226A99;
$skin-matt-color-green: #75C35E;
$skin-matt-color-greydark: #676A6C;
$skin-matt-color-grey: #BDC3C7;
$skin-matt-color-greylight: #ECF0F1;
$skin-matt-color-red: #CC3D41;
$skin-matt-color-violet: #8E44AD;
$skin-matt-color-yellow: #F1C40F;
$skin-matt-color-orange: #E67E22;
$skin-matt-color-orangedark: #D35400;

$skin-matt-colors: bluelight $skin-matt-color-bluelight $white, blue $skin-matt-color-blue $white, bluedark $skin-matt-color-bluedark $white, green $skin-matt-color-green $white $white, greydark $skin-matt-color-greydark $white $white, grey $skin-matt-color-grey $white, greylight $skin-matt-color-greylight $white, red $skin-matt-color-red $white, violet $skin-matt-color-violet $white, yellow $skin-matt-color-yellow $white, orange $skin-matt-color-orange $white, orangedark $skin-matt-color-orangedark $white;

