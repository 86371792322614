//.grid-legals {
//    display: flex;
//    flex-direction: row;
//    flex-wrap: wrap;
//
//    .grid-legal {
//        flex: 0 1 auto;
//
//        .small-box {
//            height: 100%;
//        }
//    }
//}

.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .grid-box {
        flex: 0 1 auto;
        margin-bottom: 30px;

        .small-box {
            height: 100%;
            font-weight: normal;
        }
    }
}
