.btn {
    border-radius: 0;
}

.btn-link--bmw {
    font-weight: bold;

    &:hover, &:focus {
        color: $color-bmw-blue;
    }
}

.btn-bmw, a.btn-bmw {
    color: $color-bmw-light;
    //font-family: $font-family-bmw;
    font-weight: bold;
    background: $color-bmw-light-blue;
    font-size: 16px;

    &:hover, &:focus {
        color: $color-bmw-light;
        background: $color-bmw-blue;
    }
}

.btn-bmw--mat, a.btn-bmw--mat {
    @each $color in $skin-matt-colors {
        &-#{"" + nth($color, 1)} {
            color: $color-bmw-light;
            background-color: nth($color, 2);

            &:hover, &:focus {
                color: $color-bmw-light;
                background: darken(nth($color, 2), 8);
            }
        }
    }
}