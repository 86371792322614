
body {
    overflow-x: auto;
    min-width: 768px;
    color: #262626;
}

.nav.navbar-top-links a {
    padding-top: 10px;
    padding-bottom: 10px;
}

.navbar {
    .nav > li {
        vertical-align: top;
        font-weight: 400;
    }

    .nav > li > a {
        vertical-align: top;
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: 400;
    }
}

body.fixed-sidebar.mini-navbar .navbar-static-side {
    overflow-x: hidden;
}

body.skin-bmw {

    #page-wrapper {
        @media (max-width: $screen-xs-max) {
            margin-left: 220px;
            min-height: 100vh;
        }
    }

    &.fixed-nav {
        #wrapper {
            .navbar-static-side, #page-wrapper {
                margin-top: 50px;
            }
        }
    }

    &.top-navigation {
        #wrapper {
            .navbar-static-side, #page-wrapper {
                margin-top: 0;
            }
        }
        .navbar-brand {
            margin-left: 30px;
        }
    }

    .navbar-static-side {

        @media (max-width: $screen-xs-max) {
            display: block !important;
            z-index: 2001;
            position: absolute;
            width: 220px;
            min-height: 100%;
            min-height: 100vh;
        }
    }

    #wrapper {
        @media (max-width: $screen-xs-max) {
            background: #3E495F;
        }
    }

    .navbar {
        &.navbar-fixed-top {
            transition-duration: 0s;

            .navbar-toggle {
                background: $color-bmw-blue;
                border-radius: 0;
            }

            .dropdown-menu {
                > .active {
                    > a {
                        background: $color-bmw-light;
                        color: $color-bmw-blue;
                        font-weight: bold;
                    }
                }
            }
        }

        .dropdown-menu {
            border: 1px solid #E7EAEC;
            box-shadow: none;
        }

        .navbar-header {
            background: $skin-bmw-color;
            width: 220px;
            overflow: hidden;
        }
        .navbar-minimalize {
            margin: 10px 5px;
            background: #FFFFFF;
            border-color: $skin-bmw-color;
            padding: 4px 12px;
            font-size: 14px;
            float: left;
            i {
                color: $skin-bmw-color;
            }
            &:focus {
                background: #FFFFFF;
                border-color: $skin-bmw-color;
                outline: none !important;
                box-shadow: none !important;
                i {
                    color: $skin-bmw-color;
                }
            }
        }

        .navbar-brand {
            background: $color-bmw-blue;
            font-weight: bold;
            color: #FFFFFF;
            padding: 15px 12px;
        }

        .nav > li {
            a {
                color: $skin-bmw-a-color;
                &:hover {
                    background: transparent;
                    color: $color-bmw-blue;
                }
                &:focus {
                    background: transparent;
                    color: $color-bmw-blue;
                }
            }
            &.active {
                background: transparent;
                > a {
                    color: $color-bmw-blue;
                }
            }
        }

        .nav-header {
            background-color: $skin-bmw-color;
            //background-image: url('patterns/header-profile-skin-bmw.png');
        }
        .nav-second-level {
            background: $skin-bmw-nav-bg;
        }

        .navbar-static-top {
            background: #FFFFFF;
        }

        .nav > li.active {
            border: none;
        }

        .navbar-default .nav > li > a:hover, .navbar-default .nav > li > a:focus {
            background: darken($skin-bmw-nav-bg, 2%);
            color: #FFFFFF;
        }
        .nav.nav-tabs > li.active {
            background: transparent;
            > a {
                color: #555555;
            }

        }
    }

    .form-control:focus,
    .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--multiple:focus,
    .single-line:focus {
        border-color: $color-bmw-blue-m;
    }

    .middle-box--large {
        width: 100%;
        max-width: 800px;
    }
    .spin-icon {
        background: $skin-bmw-color !important;
    }

    .breadcrumb {
        background: transparent;
    }
    .page-heading {
        border: none;
    }

    ul.nav-second-level {
        background-color: inherit;
        padding-right: 0;
    }
    .sidebar-collapse .metismenu .nav-second-level li a {
        padding-left: 10px;
    }

    .dashboard-header {
        background: transparent;
        border-bottom: none !important;
        border-top: none;
        padding: 20px 30px 10px 30px;
    }

    .wrapper-content {
        padding: 30px 15px 40px;
    }

    &.top-navigation {
        .wrapper-content {
            padding: 30px 15px 40px;
        }
    }

    .wrapper-content--no-padding-top {
        padding-top: 0;
    }

    .wrapper-content--no-padding-bottom {
        padding-bottom: 0;
    }

    .footer.fixed {
        @media (max-width: $screen-xs-max) {
            width: 100%;
            position: absolute;
            margin: 0;
        }
    }

    .tabs-container {
        .tabs-left {
            & > .nav-tabs {
                padding-right: 0;
                @media (min-width: $screen-lg-min) {
                    width: 15%;
                }
                & > li {
                    &.active {
                        & > a {
                            background-color: #FFFFFF;
                        }
                    }
                    & > a {
                        border-top: 1px solid #E7EAEC;
                        border-bottom: 1px solid #E7EAEC;
                        border-right: 1px solid #E7EAEC;
                        background-color: #E7E7E7;
                        @media (min-width: $screen-md-min) {
                            font-size: 25px;
                        }
                        .fa {
                            //font-size: 20px
                        }
                    }
                }
            }
            .panel-body {
                @media (min-width: $screen-lg-min) {
                    width: 85%;
                    margin-left: 15%;
                }
            }
        }

    }

    .ibox {
        border-radius: 3px;
        border-top: 3px solid #D2D6DE;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }
    .ibox--blue {
        border-top: 3px solid $color-bmw-blue;
        .ibox-title {
            background-color: $color-bmw-blue;
            color: white;
            border: none;
            .ibox-tools {
                a {
                    color: white;
                }
            }
        }
    }
    .ibox--mat {
        @each $color in $skin-matt-colors {
            &-#{"" + nth($color, 1)} {
                border-top-color: nth($color, 2);
            }
        }
    }

}

.dataTables_wrapper {
    padding-bottom: 0;
}

body.skin-bmw-external {
    background-color: #F2F2F2;
}

.slick-slider {
    .slick-arrow {
        &:before {
            color: $color-bmw-light-blue !important;
        }
    }
}

.ibox-title {
    position: relative;
    padding: 15px 15px 18px;

    h1, h2, h3, h4, h5, h6 {
        float: none;
        margin-right: 30px;
    }
    .label {
        margin-top: 5px;
    }
    .ibox-tools {
        position: absolute;
        top: 9px;
        right: 15px;
        //font-size: 20px;
        > a {
            vertical-align: middle;
        }
        > i {
            vertical-align: middle;
        }
        .fa-check-circle, .fa-warning {
            font-size: 20px;
        }
        /*
        .collapse-link {
            font-size: 12px;
        }
        */

    }
}

.help-text {
    font-size: 12px;
}

.text-success {
    color: #22C11C;
}

.nav-tabs > li > a {
    background-color: #E6E6E6;
    //color: #676a6c;
}

.btn-xs {
    font-size: 14px;
}